<template>
  <div class="applications_div iframe_overflow">
    <div class="create_btn">
      <Button icon="ios-add" @click="addFn">CREATE</Button>
    </div>
    <iframe class="iframe_div" :src="url" v-if="url"></iframe>
    <div class="iframe_shadow" v-if="!type"></div>
    <Modal
      v-model="showCreate"
      :width="800"
      title="Create Devices"
      footer-hide
      @on-visible-change="changeModal"
    >
      <iframe class="iframe_div" :src="addUrl" v-if="addUrl"></iframe>
    </Modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: "",
      addUrl: "",
      showCreate: false,
      type: true
    };
  },
  methods: {
    init() {
      this.url = IOT_CONFIG.paths["applications"];
      this.addUrl = IOT_CONFIG.paths["applications/create"];
      let key = this.$route.query.type;
      if (key === 'view') {
        this.type = false;
      } else {
        this.type = true;
      }
    },
    addFn() {
      this.showCreate = true;
      this.url = "";
    },
    changeModal(status) {
      if (status == false) {
        this.url = IOT_CONFIG.paths["applications"];
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang='less'>
.applications_div {
  position: relative;
  .create_btn {
    position: absolute;
    top: 116px;
    right: 60px;
  }
}
</style>